import React, { forwardRef, PropsWithChildren, ReactElement, Ref } from "react";
import * as Label from "@radix-ui/react-label";
import * as SwitchPrimitive from "@radix-ui/react-switch";
import { ReadonlyDeep } from "~/src/utils/reflection";

const SWITCH_TYPE_MAP = {
  primary: "toggle-primary",
  secondary: "toggle-secondary",
  accent: "toggle-accent",
} as const;

const SWITCH_OUTLINE_MAP = {
  primary: "outline-primary",
  secondary: "outline-secondary",
  accent: "outline-accent",
} as const;

const DEFAULT_OUTLINE =
  "outline-[oklch(var(--btn-color,_var(--b2))_/_<alpha-value>)]";

const SWITCH_SIZE_MAP = {
  lg: "toggle-lg",
  md: "toggle-md",
  sm: "toggle-sm",
  xs: "toggle-xs",
} as const;

export interface Props {
  label?: undefined | string;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  type?: undefined | keyof typeof SWITCH_TYPE_MAP;
  size?: undefined | keyof typeof SWITCH_SIZE_MAP;
}

const Switch = forwardRef(function Switch(
  props: ReadonlyDeep<PropsWithChildren<Props>>,
  ref?: Ref<HTMLSpanElement>,
): ReactElement {
  const { label, children, size = "md", type, ...switchProps } = props;
  let thumbCls = `toggle ${SWITCH_SIZE_MAP[size]}`;
  let rootClasses =
    "form-control outline-2 outline-offset-2 focus-visible:outline";
  if (type !== undefined) {
    thumbCls += ` ${SWITCH_TYPE_MAP[type]}`;
    rootClasses += ` ${SWITCH_OUTLINE_MAP[type]}`;
  } else {
    rootClasses += ` ${DEFAULT_OUTLINE}`;
  }

  return (
    <Label.Root className="flex items-center select-none gap-2">
      {label ?? children}
      <SwitchPrimitive.Root className={rootClasses} {...switchProps}>
        <SwitchPrimitive.Thumb
          className={thumbCls}
          ref={ref}
          data-testid="thumb"
        />
      </SwitchPrimitive.Root>
    </Label.Root>
  );
});

export default Switch;
