import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import { ReadonlyDeep } from "../utils/reflection";

export function useStateRef<S>(
  initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>, ReadonlyDeep<MutableRefObject<S>>] {
  const [state, realSetState] = useState(initialState);
  const ref = useRef(state);
  const setState: Dispatch<SetStateAction<S>> = useCallback(
    (value: SetStateAction<S>) => {
      if (typeof value === "function") {
        realSetState((prevState) => {
          const newState = (value as (prevState: S) => S)(prevState);
          ref.current = newState;
          return newState;
        });
      } else {
        ref.current = value;
        realSetState(value);
      }
    },
    [],
  );

  return [state, setState, ref as ReadonlyDeep<MutableRefObject<S>>];
}
