import React, { ReactElement } from "react";

export interface Props {
  mainContentId?: undefined | string;
}

export function SkipNavLinks({ mainContentId }: Props): ReactElement | null {
  if (mainContentId === undefined) {
    return null;
  }

  return (
    <div className="skipnavlinks">
      <a href={`#${mainContentId}`}>Vai al contenuto</a>
    </div>
  );
}
