import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export interface UseResize {
  readonly element: HTMLElement | null;
  readonly setElement: (observingElement: HTMLElement) => void;
  readonly addEventListener: (callback: Callback) => void;
  readonly removeEventListener: (callback: Callback) => void;
}

export type Callback = (observingElement: HTMLElement) => void;

export function useResize(): UseResize {
  const callbacksRef = useRef<Callback[]>([]);
  const [element, setElement] = useState<HTMLElement | null>(null);

  return useResizeImpl(callbacksRef, element, setElement);
}

export function useResizeImpl(
  callbacksRef: MutableRefObject<Callback[]>,
  element: HTMLElement | null,
  setElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
): UseResize {
  useEffect(() => {
    if (element === null) {
      return;
    }

    for (const callback of callbacksRef.current) {
      callback(element);
    }

    const resizeObserver = new ResizeObserver(() => {
      for (const callback of callbacksRef.current) {
        callback(element);
      }
    });
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [callbacksRef, element]);

  const addEventListener = useCallback(
    (callback: Callback) => {
      callbacksRef.current.push(callback);
    },
    [callbacksRef],
  );

  const removeEventListener = useCallback(
    (callback: Callback) => {
      const index = callbacksRef.current.indexOf(callback);
      if (index !== -1) {
        callbacksRef.current.splice(index, 1);
      }
    },
    [callbacksRef],
  );

  return { element, setElement, addEventListener, removeEventListener };
}
