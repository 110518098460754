import React from "react";
import PropTypes from "prop-types";

import { MdMusicNote, MdSmartDisplay } from "react-icons/md";
import Icon from "~/src/components/Icon";
import { ModalPlayer } from "~/src/components/Player";
import { useEditorContext } from "~/src/Editor/EditorContext";
import Spinner from "~/src/components/Spinner";

import logoMyLIM from "~/src/assets/myLIM-icon.png";

const MYLIM_RES_TYPE_ICONS = {
  audio: MdMusicNote,
  video: MdSmartDisplay,
};

function getIconForType(type) {
  const icon = MYLIM_RES_TYPE_ICONS[type];
  return icon ? <Icon icon={icon} /> : <></>;
}

function renderResource(row) {
  const { id, type, titolo, url, sottotitoli = [] } = row;
  const mediaData = { titolo, url, sottotitoli };
  const iconLabel = `${type[0].toUpperCase()}${type.substring(1)}:`;
  return (
    <li
      key={id}
      className="text-base bg-base-100 odd:bg-base-200 hover:bg-primary hover:text-primary-content"
    >
      <ModalPlayer media={mediaData}>
        <button className="w-full flex items-center px-6 py-2 focus-within:outline-none focus-within:bg-primary focus-within:text-primary-content">
          <span className="mr-6" aria-label={iconLabel}>
            {getIconForType(type)}
          </span>
          <span className="flex-1 text-left whitespace-nowrap overflow-x-hidden overflow-ellipsis">
            {titolo}
          </span>
        </button>
      </ModalPlayer>
    </li>
  );
}

function MyLIMContentInner({ allocatedHeading, element }) {
  const { contents } = element;

  const { levelOf } = useEditorContext();
  let HeadingTag = null;
  if (allocatedHeading) {
    const headingLevel = levelOf(allocatedHeading.id);
    if (headingLevel !== undefined) {
      HeadingTag = `h${headingLevel}`;
    }
  }

  if (HeadingTag === null) {
    return <Spinner />;
  }

  return (
    <>
      <div className="card-title flex items-center justify-between px-4 py-4 border-b border-neutral select-none">
        <HeadingTag className="uppercase text-lg tracking-wider font-bold cursor-default">
          Contenuti da myLIM
        </HeadingTag>
        <img src={logoMyLIM} alt="myLIM Logo" className="h-6" />
      </div>
      <ul className="max-h-64 lg:max-h-52 overflow-y-scroll focus-within:outline outline-primary outline-2 outline-offset-2">
        {contents.map((row) => renderResource(row))}
      </ul>
    </>
  );
}

MyLIMContentInner.propTypes = {
  allocatedHeading: PropTypes.shape({
    id: PropTypes.shape({
      value: PropTypes.number,
      __sym: PropTypes.symbol,
    }),
  }),
  /**
   * Proprietà generata da Slate.js che contiene informazioni sull'elemento
   */
  element: PropTypes.shape({
    /**
     * Array di blocchi.
     */
    contents: PropTypes.array.isRequired,
  }),
};

function MyLIMContent({ attributes, element, children }) {
  const { allocateAutomaticHeading } = useEditorContext();
  let [allocatedHeading, setAllocatedHeading] = React.useState(null);
  React.useEffect(() => {
    const allocatedHeading = allocateAutomaticHeading();
    setAllocatedHeading(allocatedHeading);
    return () => allocatedHeading.remove();
  }, [allocateAutomaticHeading]);

  return (
    <aside
      {...attributes}
      contentEditable={false}
      // NOTE: the `not-prose` class disables TailwindCSS/typography in the block
      className="card compact border border-neutral rounded not-prose my-4"
    >
      {allocatedHeading !== null && (
        <MyLIMContentInner
          allocatedHeading={allocatedHeading}
          element={element}
        />
      )}
      <>{children}</>
    </aside>
  );
}

MyLIMContent.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: PropTypes.any,
  children: PropTypes.any,
  /**
   * Proprietà generata da Slate.js che contiene informazioni sull'elemento
   */
  element: PropTypes.shape({
    /**
     * Array di blocchi.
     */
    contents: PropTypes.array.isRequired,
  }),
};

export default MyLIMContent;
