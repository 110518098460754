import React from "react";
import { ReactEditor } from "slate-react";
import {
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdTexture,
  MdLocalParking,
  MdTitle,
  MdChecklistRtl,
  MdLightbulb,
} from "react-icons/md";

import {
  changeToBulletedList,
  changeToOrderedList,
  changeToParagraph,
  changeToQuote,
  changeToTitle,
  removeHighlightBox,
  wrapHighlightBox,
  changeToAlphabeticList,
  changeToExample,
  BlockElement,
} from "./BlockConverter";
import { ButtonAction } from "../../components/Button";

interface CommonProps {
  // Editor di Slate.js
  editor: ReactEditor;
  // Element di Slate.js
  element: BlockElement;
}

/**
 * Componente bottone per cambiare il blocco in paragrafo
 */
export function ButtonConverterToParagraph({ editor, element }: CommonProps) {
  return (
    <ButtonAction
      ariaLabel="Trasforma in paragrafo"
      onClick={() => {
        changeToParagraph(editor, element);
      }}
      Icon={MdLocalParking}
    />
  );
}

/**
 * Componente bottone per cambiare il blocco in bulleted list
 * @param {*} param
 */
export function ButtonConverterToBulletedList({
  editor,
  element,
}: CommonProps) {
  return (
    <ButtonAction
      ariaLabel="Trasforma in lista non ordinata"
      onClick={() => {
        changeToBulletedList(editor, element);
      }}
      Icon={MdFormatListBulleted}
    />
  );
}

/**
 * Componente bottone per cambiare il blocco in ordered list
 * @param {*} param
 */
export function ButtonConverterToOrderedList({ editor, element }: CommonProps) {
  return (
    <ButtonAction
      ariaLabel="Trasforma in lista ordinata"
      onClick={() => {
        changeToOrderedList(editor, element);
      }}
      Icon={MdFormatListNumbered}
    />
  );
}

/**
 * Componente bottone per cambiare il blocco in lista alfabetica ordinata
 * @param {*} param
 */
export function ButtonConverterToAlphabeticList({
  editor,
  element,
}: CommonProps) {
  return (
    <ButtonAction
      ariaLabel="Trasforma in lista alfabetica"
      onClick={() => {
        changeToAlphabeticList(editor, element);
      }}
      Icon={MdChecklistRtl}
    />
  );
}

/**
 * Componente bottone per cambiare il blocco in title
 * @param {*} param
 */
export function ButtonConverterToTitle({ editor, element }: CommonProps) {
  return (
    <ButtonAction
      ariaLabel="Trasforma in intestazione"
      onClick={() => {
        changeToTitle(editor, element);
      }}
      Icon={MdTitle}
    />
  );
}

/**
 * Componente bottone per rimuovere il focux box dal blocco
 * @param {*} param
 */
export function ButtonRemoveHighlightBox({ editor, element }: CommonProps) {
  return (
    <ButtonAction
      ariaLabel="Rimuovi blocco in evidenza"
      onClick={() => {
        removeHighlightBox(editor, element);
      }}
      Icon={MdTexture}
    />
  );
}

/**
 * Componente bottone per wrappare blocco in highlight box
 * @param {*} param
 */
export function ButtonWrapHighlightBox({ editor, element }: CommonProps) {
  const path = ReactEditor.findPath(editor, element);

  if (path.length > 1) {
    return null;
  } else {
    return (
      <ButtonAction
        ariaLabel="Trasforma in blocco in evidenza"
        onClick={() => {
          wrapHighlightBox(editor, path);
        }}
        Icon={MdTexture}
      />
    );
  }
}

/**
 * Componente bottone per transformare un testo in quote
 * @param {*} param
 */
export function ButtonConverterToQuote({ editor, element }: CommonProps) {
  return (
    <ButtonAction
      ariaLabel="Trasforma in citazione"
      onClick={() => {
        changeToQuote(editor, element);
      }}
      Icon={MdFormatQuote}
    />
  );
}

/**
 * Componente bottone per transformare un testo in esempio
 * @param {*} param
 */
export function ButtonConverterToExample({ editor, element }: CommonProps) {
  return (
    <ButtonAction
      ariaLabel="Trasforma in esempio"
      onClick={() => {
        changeToExample(editor, element);
      }}
      Icon={MdLightbulb}
    />
  );
}
