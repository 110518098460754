import React, { ReactElement, ReactNode } from "react";
import classNames from "classnames";

export interface Props<T extends string> {
  /**
   * Il titolo del radio group (fungerà da label per il radio group).
   */
  readonly description: string;
  /**
   * Il valore che verrà utilizzato come `name` nei children `input[type="radio"]`.
   */
  readonly name: string;
  /**
   * I possibili valori che può assumere il radio button.
   */
  readonly options: readonly Readonly<Option<T>>[];
  /**
   * L'opzione correntemente attiva del radio-group.
   * Deve corrispondere ad uno dei valori definito in `options[*].val`.
   */
  readonly activeOption: T;
  /**
   * La callback chiamata quando viene cambiata la selezione del radio-group.
   */
  readonly onChange: (value: T) => void;
}

export interface OptionBase<T extends string> {
  /**
   * Il valore che assume il radio group se l'opzione è selezionata.
   */
  val: T;
  /**
   * Una stringa di classi da applicare all'opzione.
   * Visto che si tratta di stili specifici per una singola opzione,
   * preferire l'uso delle classi di utilità definite da TailwindCSS.
   */
  style?: undefined | string;
}

export type OptionContent =
  | NonNullable<unknown>
  | {
      /**
       * Il contenuto visibile all'utente che identifica un'opzione del
       * radio-group. Accetta una stringa o del JSX per i casi più complessi.
       */
      content: string;
      /**
       * Il contenuto visible a livello di accessibilità. Nel caso sia specificato,
       * il contenuto verrà incapsulato in uno `span` con `aria-hidden="true"`.
       */
      accessibleContent?: undefined | string;
    }
  | {
      /**
       * Il contenuto visibile all'utente che identifica un'opzione del
       * radio-group. Accetta una stringa o del JSX per i casi più complessi.
       */
      content: ReactNode;
    };

export type Option<T extends string> = OptionBase<T> & OptionContent;

/**
 * Componente che renderizza un radio group per la selezione di un aspetto del
 * tema. Raccoglie quanto comune a tutte i selettori di opzioni per il tema.
 */
function UICustomizableOption<T extends string>({
  description,
  name,
  options,
  activeOption,
  onChange,
}: Props<T>) {
  const radioGroupName = `ui-customization-${name}`;

  return (
    <div className="ui-customizer__option">
      <p
        id={`${radioGroupName}-label`}
        className="text-xs font-sans font-semibold uppercase mb-1"
      >
        {description}
      </p>
      <div
        role="radiogroup"
        aria-labelledby={`${radioGroupName}-label`}
        className="join grid grid-flow-col"
      >
        {options.map(({ val, style: optionStyle, ...rest }) => {
          const btnCls = classNames(
            "btn normal-case join-item outline-2 outline-offset-2",
            optionStyle,
            {
              "btn-outline": activeOption !== val,
              "btn-secondary": activeOption === val,
            },
          );
          return (
            <label key={val} className={btnCls}>
              <OptionContent {...rest} />
              <input
                className="appearance-none"
                type="radio"
                name={radioGroupName}
                value={val}
                checked={activeOption === val}
                onChange={(evt) => {
                  // Granted by the type of `value`
                  onChange(evt.target.value as T);
                }}
              />
            </label>
          );
        })}
      </div>
    </div>
  );
}

function OptionContent(props: OptionContent): ReactElement | null {
  if (!("content" in props)) {
    return null;
  }

  let contentAriaHidden: "true" | undefined;
  let accessibleElement: ReactElement | null;
  if ("accessibleContent" in props && props.accessibleContent !== undefined) {
    contentAriaHidden = "true";
    accessibleElement = (
      <span className="sr-only">{props.accessibleContent}</span>
    );
  } else {
    accessibleElement = null;
  }

  return (
    <>
      <span
        className="h-full grid place-items-center"
        aria-hidden={contentAriaHidden}
      >
        {props.content}
      </span>
      {accessibleElement}
    </>
  );
}

export default UICustomizableOption;
