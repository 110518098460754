import React, { ReactElement, Suspense, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import { MdClose } from "react-icons/md";
import Icon from "~/src/components/Icon";
import { Media } from "./Player.jsx";

// Carichiamo il player video solo al bisogno
const Player = React.lazy(() => import("./Player"));

export interface Props {
  // I dati del file multimediale da riprodurre
  media: Media;
  // L'elemento che triggera l'apparizione del ModalPlayer
  children?: ReactElement | undefined;
}

export function ModalPlayer({ children, media }: Props): ReactElement {
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog.Root open={open}>
      <Dialog.Trigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed w-screen h-screen bg-gray-900 opacity-70 top-0 left-0 z-50" />
        <Dialog.Content
          className="fixed w-full max-w-screen-xl max-h-screen right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 z-50"
          onEscapeKeyDown={closeModal}
          onPointerDownOutside={closeModal}
          aria-describedby={undefined}
        >
          <Dialog.Title className="sr-only">Lettore video</Dialog.Title>
          <div className="max-w-screen-xl max-h-screen m-4 p-0 rounded">
            <Suspense fallback={<div>Caricamento ...</div>}>
              <Player
                media={media}
                closeBtn={
                  <button onClick={closeModal}>
                    <Icon
                      icon={MdClose}
                      label="Chiudi player multimediale"
                      size="1.2rem"
                    />
                  </button>
                }
              />
            </Suspense>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
