import React, { useState, useEffect, useCallback } from "react";
import { MdSettings } from "react-icons/md";

import {
  Popover,
  PopoverTrigger,
  PopoverClose,
  PopoverContent,
} from "~/src/components/Popover";
import UICustomizableOption from "./UICustomizableOption";
import ColorSample from "./ColorSample";
import Button from "~/src/components/Button";
import Icon from "~/src/components/Icon";

import * as themes from "~/src/utils/themes";
import {
  setFontFamily,
  setFontSize,
  setFontSpace,
  setFontCase,
  setThemeColor,
  setThemeFromConf,
} from "../../../utils/themes";

function UICustomizer() {
  const [isOpen, setIsOpen] = useState(false);
  const [conf, setConf] = useState<null | themes.Config>(null);

  useEffect(() => {
    const rawThemeConf = localStorage.getItem("themeConf");
    let themeConf: themes.Config | undefined;
    if (rawThemeConf !== null) {
      const parsedThemeConf: unknown = JSON.parse(rawThemeConf);
      if (themes.isConfig(parsedThemeConf)) {
        themeConf = parsedThemeConf;
      } else {
        console.warn("Stored theme config is invalid");
      }
    }

    themeConf ??= getDefaultConfig();
    setThemeFromConf(themeConf);
    setConf(themeConf);
  }, []);

  useEffect(() => {
    if (conf) {
      localStorage.setItem("themeConf", JSON.stringify(conf));
    }
  }, [conf]);

  const onChangeFontFamily = useCallback((val: themes.FontFamily) => {
    setConf((s) => ({ ...(s ?? getDefaultConfig()), fontFamily: val }));
    setFontFamily(val);
  }, []);

  const onChangeFontSize = useCallback((val: themes.FontSize) => {
    setConf((s) => ({ ...(s ?? getDefaultConfig()), fontSize: val }));
    setFontSize(val);
  }, []);

  const onChangeFontSpace = useCallback((val: themes.FontSpace) => {
    setConf((s) => ({ ...(s ?? getDefaultConfig()), fontSpace: val }));
    setFontSpace(val);
  }, []);

  const onChangeFontCase = useCallback((val: themes.FontCase) => {
    setConf((s) => ({ ...(s ?? getDefaultConfig()), fontCase: val }));
    setFontCase(val);
  }, []);

  const onChangeThemeColor = useCallback((val: themes.Theme) => {
    setConf((s) => ({ ...(s ?? getDefaultConfig()), themeColor: val }));
    setThemeColor(val);
  }, []);

  if (!conf) {
    return null;
  }

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button type="ghost" square>
          <Icon
            icon={MdSettings}
            label={`${isOpen ? "Chiudi" : "Apri"} impostazioni tema`}
            className="w-full h-full p-2"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <>
          <UICustomizableOption
            description="Tipo carattere"
            name="font-family"
            options={[
              {
                val: "serif",
                content: "Serif",
                accessibleContent: "Con grazie",
                style: "font-serif",
              },
              {
                val: "sans",
                content: "Sans",
                accessibleContent: "Senza grazie",
                style: "font-sans",
              },
              {
                val: "readable",
                content: "Alta Leggibilità",
                accessibleContent: "Ad alta leggibilità",
                style: "text-xs font-al",
              },
            ]}
            activeOption={conf.fontFamily}
            onChange={onChangeFontFamily}
          />
          <UICustomizableOption
            description="Dimensione carattere"
            name="font-size"
            options={[
              {
                val: "0",
                content: "a",
                accessibleContent: "Normale",
              },
              {
                val: "1",
                content: "a",
                accessibleContent: "Grande",
                style: "text-xl",
              },
              {
                val: "2",
                content: "a",
                accessibleContent: "Extra",
                style: "text-2xl",
              },
            ]}
            activeOption={conf.fontSize}
            onChange={onChangeFontSize}
          />
          <UICustomizableOption
            description="Dimensione interlinea"
            name="font-space"
            options={[
              {
                val: "normal",
                content: "1x",
                accessibleContent: "Normale",
              },
              {
                val: "large",
                content: "1.5x",
                accessibleContent: "Larga",
              },
              {
                val: "double",
                content: "2x",
                accessibleContent: "Doppia",
              },
            ]}
            activeOption={conf.fontSpace}
            onChange={onChangeFontSpace}
          />
          <UICustomizableOption
            description="Minuscolo / Maiuscolo"
            name="font-case"
            options={[
              {
                val: "normal",
                content: "a",
                accessibleContent: "Normale",
              },
              {
                val: "uppercase",
                content: "A",
                accessibleContent: "Tutto maiuscolo",
              },
            ]}
            activeOption={conf.fontCase}
            onChange={onChangeFontCase}
          />
          <UICustomizableOption
            description="Tema colori"
            name="theme"
            options={[
              {
                val: "light",
                content: <ColorSample name="Chiaro" color="#FFFFFF" />,
                style: "btn-lg",
              },
              {
                val: "dark",
                content: <ColorSample name="Scuro" color="#000000" />,
                style: "btn-lg",
              },
              {
                val: "retro",
                content: <ColorSample name="Seppia" color="#E4D7B4" />,
                style: "btn-lg",
              },
            ]}
            activeOption={conf.themeColor}
            onChange={onChangeThemeColor}
          />
          <PopoverClose asChild>
            <button className="sr-only">Chiudi impostazioni tema</button>
          </PopoverClose>
        </>
      </PopoverContent>
    </Popover>
  );
}

function getDefaultConfig(): themes.Config {
  return {
    fontFamily: "sans",
    fontSize: "0",
    fontSpace: "normal",
    fontCase: "normal",
    themeColor: window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
  };
}

export default UICustomizer;
