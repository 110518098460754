import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Book from "./Pages/Book";
import BookChapter from "./Pages/BookChapter";
import AuthProvider from "./Auth";
import Login from "./Pages/Login";
import CookieBanner from "./components/CookieBanner";
import { useResize } from "./hooks/useResize";
import { updateNoStickyAttributeFromBarHeights } from "./utils/resize";
import { SkipNavLinks } from "./components/SkipNavLinks";

/**
 * come da richiesta, se non sono presenti in url ISBN, mandiamo
 * direttamente su mylim
 */
const RedirectToMylim = () => {
  window.location.replace("https://mylim.loescher.it");
  return null;
};

function App() {
  const resizeTopbar = useResize();
  useEffect(() => {
    window.addEventListener("resize", updateNoStickyAttributeFromBarHeights);
    return () =>
      window.removeEventListener(
        "resize",
        updateNoStickyAttributeFromBarHeights,
      );
  }, []);

  const [mainContentId, setMainContentId] = useState();

  return (
    <AuthProvider>
      <SkipNavLinks mainContentId={mainContentId} />
      <Routes>
        {/* Gestisce sommario e titolo opera, redirect al capitolo se serve */}
        <Route
          path="/books/:isbn/"
          element={
            <Book
              setTopbar={resizeTopbar.setElement}
              setMainContentId={setMainContentId}
            />
          }
        >
          {/* Gestisce il capitolo scelto */}
          <Route
            path=":chapter_id"
            element={
              <BookChapter
                resizeTopbar={resizeTopbar}
                setMainContentId={setMainContentId}
              />
            }
          />
        </Route>

        <Route path="/login" element={<Login />} />

        <Route path="/" element={<RedirectToMylim />} />
      </Routes>

      <Toaster />
      <CookieBanner />
    </AuthProvider>
  );
}

export default App;
