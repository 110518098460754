import React from "react";
import PropTypes from "prop-types";
import { createEditor, Transforms, Node, Editor as SlateEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";

import { withDocxDeserializer } from "./DocxDeserializer";
import { ELEMENT_TYPES } from "./constants";
import Toolbar, { ToolbarContext } from "./Toolbar";
import Leaf from "./Leaf";
import Element from "./Element";
import { isBlockDefaultEnter } from "./utils";
import {
  normalizeQuote,
  onEnterQuoteSource,
  onEnterQuoteText,
} from "./Blocks/Quote";
import TopLevelCreationTools from "./TopLevelCreationTools";
import { onEnterExampleText } from "./Blocks/Example";
import { EditorProvider } from "./EditorContext";

Editor.propType = {
  /**
   * Flag che determina se è abilitata modalità editing di SlateJS.
   */
  readOnly: PropTypes.bool.isRequired,
  /**
   * Callback per aggiornare l'array di blocchi.
   */
  setContents: PropTypes.func.isRequired,
  /**
   * Array di blocchi.
   */
  contents: PropTypes.array.isRequired,
};

const withLayout = (editor) => {
  const { normalizeNode } = editor;

  // eslint-disable-next-line
  editor.normalizeNode = ([node, path]) => {
    switch (node.type) {
      case ELEMENT_TYPES.quote:
        normalizeQuote(editor, node, path);
        return;
      default:
        return normalizeNode([node, path]);
    }
  };

  return editor;
};

function Editor({ readOnly = false, contents, setContents }) {
  const editor = React.useMemo(
    () => withDocxDeserializer(withLayout(withReact(createEditor()))),
    [],
  );
  const [toolbarButtons, setToolbarButtons] = React.useState([]);

  const renderLeaf = React.useCallback((props) => <Leaf {...props} />, []);
  const renderElement = React.useCallback(
    (props) => <Element {...props} />,
    [],
  );

  const onKeyDown = React.useCallback(
    (event) => {
      if (!editor.selection) return;

      const parentNode = Node.parent(editor, editor.selection.focus.path);

      if (event.key === "Backspace" && editor.selection.focus.offset === 0) {
        event.preventDefault();
        return;
      }

      if (event.key === "Enter") {
        if (isBlockDefaultEnter(editor)) {
          return;
        }

        if (parentNode.type === ELEMENT_TYPES.quoteParagraph) {
          event.preventDefault();
          onEnterQuoteText(editor);
          return;
        }

        if (parentNode.type === ELEMENT_TYPES.quoteSource) {
          event.preventDefault();
          onEnterQuoteSource(editor);
          return;
        }

        if (parentNode.type === ELEMENT_TYPES.exampleParagraph) {
          event.preventDefault();
          onEnterExampleText(editor);
          return;
        }

        if (parentNode.type !== ELEMENT_TYPES.paragraph) {
          // Se si preme invio fuori da un paragrafo il cursore si sposta a fine blocco e
          // inserisce un nuovo nodo paragrafo.
          event.preventDefault();
          Transforms.select(
            editor,
            SlateEditor.end(editor, editor.selection.focus.path),
          );
          Transforms.insertNodes(editor, {
            type: ELEMENT_TYPES.paragraph,
            children: [{ text: "" }],
          });
        }
      }
    },
    [editor],
  );

  const { isInline, isVoid } = editor;
  editor.isInline = (element) =>
    [ELEMENT_TYPES.glossario, ELEMENT_TYPES.link].includes(element.type) ||
    isInline(element);
  editor.isVoid = (element) =>
    ELEMENT_TYPES.mylimcontent === element.type || isVoid(element);

  const toolbarContextData = React.useMemo(
    () => ({
      setButtons: setToolbarButtons,
    }),
    [setToolbarButtons],
  );

  return (
    <EditorProvider>
      <Slate
        editor={editor}
        value={contents}
        onChange={(newValue) => setContents(newValue)}
      >
        {!readOnly && <Toolbar buttons={toolbarButtons} />}
        <ToolbarContext.Provider value={toolbarContextData}>
          <EditorMemo
            readOnly={readOnly}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={onKeyDown}
          />
        </ToolbarContext.Provider>
      </Slate>
    </EditorProvider>
  );
}

Editor.propTypes = {
  readOnly: PropTypes.bool,
  contents: PropTypes.array.isRequired,
  setContents: PropTypes.func.isRequired,
};

function EditorMemoImpl({ readOnly, renderElement, renderLeaf, onKeyDown }) {
  return (
    <div className="editor-contents">
      {!readOnly && <TopLevelCreationTools />}
      <Editable
        readOnly={readOnly}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}

EditorMemoImpl.propTypes = {
  readOnly: PropTypes.bool,
  renderElement: PropTypes.func,
  renderLeaf: PropTypes.func,
  onKeyDown: PropTypes.func,
};
const EditorMemo = React.memo(EditorMemoImpl);

export default Editor;
