import { Editor, Transforms, Element as SlateElement, Path } from "slate";
import { ReactEditor } from "slate-react";
import { ElementTypesValues } from "../constants";

/**
 * Seleziona l'intero blocco per poter applicare trasformazioni
 * @param editor Editor di Slate.js
 * @param path path dell'elemento
 */
export function selectBlock(editor: ReactEditor, path: Path) {
  Transforms.select(editor, {
    anchor: Editor.start(editor, path),
    focus: Editor.end(editor, path),
  });
}

/**
 * Se si passa il parametro at applica l'unwrap
 * al quel path, altrimenti applica l'unwrap alla selection.
 * @param editor Editor di Slate.js
 * @param type il tipo di elemento da rimuovere
 * @param path dove applicare l'unwrap
 */
export function unwrapNodes(
  editor: ReactEditor,
  type: ElementTypesValues,
  at?: Path,
) {
  const opts: Parameters<typeof Transforms.unsetNodes>[2] = {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      "type" in n &&
      n.type === type,
  };
  if (at !== undefined) {
    opts.at = at;
  }
  Transforms.unwrapNodes(editor, opts);
}

/**
 * Rimuove gli stili dalla selezione
 * @param {Object} editor Editor di Slate.js
 */
export function removeMarks(editor: ReactEditor) {
  Editor.removeMark(editor, "bold");
  Editor.removeMark(editor, "italic");
  Editor.removeMark(editor, "code");
  Editor.removeMark(editor, "uppercase");
}

/**
 * Sposta il cursore alla fine del path indicato
 * @param {Object} editor Editor di Slate.js
 * @param {Array} path path dell'elemento
 */
export function selectEndPath(editor: ReactEditor, path: Path) {
  Transforms.select(editor, {
    anchor: Editor.end(editor, path),
    focus: Editor.end(editor, path),
  });
}
