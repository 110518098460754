import React, {
  ChangeEvent,
  ImgHTMLAttributes,
  useCallback,
  useRef,
} from "react";
import { useReadOnly, ReactEditor, useSlateStatic } from "slate-react";
import { Transforms, Element as SlateElement } from "slate";
import * as Dialog from "@radix-ui/react-dialog";
import { MdClose, MdFileUpload, MdFullscreen } from "react-icons/md";
import toast from "react-hot-toast";

import { useToolbar, DEFAULT_TOOLBAR_BUTTONS } from "../../Toolbar";
import Icon from "../../../components/Icon";
import Button, { ButtonAction } from "../../../components/Button";
import useModifiers from "../../useModifiersContext";
import { ButtonWrapHighlightBox } from "../../BlockConverter";
import { apiUploadImage } from "../../../Api";
import { isObject, isString } from "~/src/utils/narrowing";
import { ELEMENT_TYPES } from "~/src/Editor/constants";

export interface Props {
  /**
   * Passati e gestiti da Slate.js
   */
  element: Element;
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: ImgHTMLAttributes<HTMLImageElement>;
  /**
   * Passati e gestiti da Slate.js
   */
  children: unknown;
}

export type Element = SlateElement & {
  type: typeof ELEMENT_TYPES.image;
  url: string;
  aspectRatio: string;
  altText: string;
};

const EMPTY_ARRAY: unknown[] = [];

/**
 * Componente che renderizza un blocco immagine con descrizione
 * @param {*} param vedi PropTypes
 * @returns Un blocco immagine con descrizione
 */
function ImageBlock({ attributes, element, children = EMPTY_ARRAY }: Props) {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isUploadingImage, setIsUploadingImage] = React.useState(false);
  const editor = useSlateStatic() as ReactEditor;
  const isReadOnly = useReadOnly();
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  const path = ReactEditor.findPath(editor, element);
  const converterBtns = [
    <ButtonWrapHighlightBox
      key="wrapHighlightBox"
      editor={editor}
      element={element}
    />,
  ];
  if (path.length > 1) converterBtns.pop();

  useModifiers({ converterBtns, element });

  const uploadImage = (uploadEvent: ChangeEvent<HTMLInputElement>) => {
    const file = uploadEvent.target.files?.[0];
    if (file) {
      const FR = new FileReader();

      FR.addEventListener("loadend", (e) => {
        void (async () => {
          setIsUploadingImage(true);
          const formData = new FormData();
          formData.append("file", file);

          let imageUrl: null | string = null;
          try {
            const res = await toast.promise(
              apiUploadImage(file.name, formData),
              {
                loading: "Caricamento immagine...",
                success: "Immagine caricata",
                error: "Errore nel caricamento dell'immagine",
              },
            );
            if (isObject(res) && "url" in res && isString(res.url)) {
              imageUrl = res.url;
            } else {
              if (inputRef.current !== null) {
                inputRef.current.value = "";
              }
              return;
            }
          } catch (_) {
            if (inputRef.current !== null) {
              inputRef.current.value = "";
            }
            return;
          } finally {
            setIsUploadingImage(false);
          }

          const image = new Image(); // get aspect ratio of the new image
          if (typeof e.target?.result === "string") {
            image.src = e.target.result;
          }

          image.onload = function () {
            Transforms.setNodes<Element>(
              editor,
              { url: imageUrl, aspectRatio: `${image.width}/${image.height}` },
              { at: path },
            );
          };
        })();
      });
      if (uploadEvent.target.files?.[0] !== undefined) {
        FR.readAsDataURL(uploadEvent.target.files[0]);
      }
    }
  };

  const onUpload = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const stopMouseEventPropagation = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
    },
    [],
  );

  const previewTriggerRef = useRef<HTMLButtonElement | null>(null);
  const onContentCloseAutoFocus = useCallback((event: Event) => {
    const previewTrigger = previewTriggerRef.current;
    if (previewTrigger === null) {
      return;
    }

    previewTrigger.focus({ preventScroll: true });
    event.preventDefault();
  }, []);

  if (isReadOnly) {
    return (
      <div {...attributes}>
        <Dialog.Root>
          <Dialog.Trigger
            asChild
            aria-label="ingrandisci immagine"
            style={{ aspectRatio: element.aspectRatio }}
          >
            <figure className="relative">
              <img
                className="w-full hover:cursor-pointer"
                src={element.url}
                alt={element.altText}
              />
              <figcaption>{children}</figcaption>
              <Dialog.Trigger
                ref={previewTriggerRef}
                asChild
                onClick={stopMouseEventPropagation}
              >
                <Button
                  classes="absolute right-4 top-4"
                  square
                  outline
                  type="primary"
                  size="sm"
                  aria-label="Visualizza immagine a schermo intero"
                >
                  <Icon icon={MdFullscreen} size="30px" />
                </Button>
              </Dialog.Trigger>
            </figure>
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed w-full h-full bg-gray-900 opacity-70 top-0 z-50" />
            <Dialog.Content
              aria-describedby={undefined}
              className="fixed w-11/12 right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 flex justify-center z-50"
              onCloseAutoFocus={onContentCloseAutoFocus}
            >
              <Dialog.Title className="sr-only">
                Visualizzatore immagini
              </Dialog.Title>
              <Dialog.Close className="absolute right-0 top-0 btn btn-ghost btn-square btn-md text-white">
                <Icon icon={MdClose} label="chiudi" size="30px" />
              </Dialog.Close>
              <img src={element.url} alt={element.altText} />
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    );
  }

  const onAltTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    Transforms.setNodes<Element>(
      editor,
      { altText: e.target.value },
      { at: path },
    );
  };

  return (
    <div {...attributes}>
      <figure>
        <div
          className="image"
          contentEditable={false}
          style={{ aspectRatio: element.aspectRatio }}
        >
          <span className="image__bg"></span>
          <div className="image__upload">
            <input
              accept="image/*"
              aria-hidden="true"
              data-testid="file-input"
              ref={inputRef}
              className="hidden"
              type="file"
              onChange={uploadImage}
            />
            {!isUploadingImage && (
              <ButtonAction
                ariaLabel="Carica nuova immagine"
                onClick={onUpload}
                Icon={MdFileUpload}
              />
            )}
          </div>
          <img className="w-full" src={element.url} alt={element.altText} />
        </div>
        <div contentEditable={false}>
          <label className="text-xs font-bold">
            Testo alternativo
            <textarea
              defaultValue={element.altText}
              onChange={onAltTextChange}
              className="textarea textarea-primary w-full text-base-content"
              placeholder="Testo alternativo"
            ></textarea>
          </label>
        </div>
        <figcaption>{children}</figcaption>
      </figure>
    </div>
  );
}

export default ImageBlock;
