import React from "react";
import PropTypes from "prop-types";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";

import useModifiers from "../../useModifiersContext";
import ModifierButton, { BACKGROUND_COLORS } from "./HighlightBoxModifiers";
import { ButtonRemoveHighlightBox } from "~/src/Editor/BlockConverter";

HighlightBox.propTypes = {
  /**
   * Attributi generati da Slate.js da assegnare all'elemento
   */
  attributes: PropTypes.object,
  /**
   * Children generati da Slate.js da inserire all'elemento
   */
  children: PropTypes.array,
  /**
   * Proprietà generata da Slate.js che contiene informazioni sull'elemento
   */
  element: PropTypes.shape({
    background: PropTypes.string,
  }),
};

/**
 * Componente che renderizza un box con sfondo giallo
 * @param {*} props
 */
function HighlightBox({ attributes, children, element }) {
  const editor = useSlateStatic();

  const onClick = (background) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { ...element, background }, { at: path });
  };

  const modifierBtns = [
    <ModifierButton
      key="primary"
      color={BACKGROUND_COLORS.primary}
      onClick={() => onClick(BACKGROUND_COLORS.primary)}
    />,
    <ModifierButton
      key="secondary"
      color={BACKGROUND_COLORS.secondary}
      onClick={() => onClick(BACKGROUND_COLORS.secondary)}
    />,
    <ModifierButton
      key="accent"
      color={BACKGROUND_COLORS.accent}
      onClick={() => onClick(BACKGROUND_COLORS.accent)}
    />,
  ];

  const converterBtns = [
    <ButtonRemoveHighlightBox
      key="removeHighlightBox"
      editor={editor}
      element={element}
    />,
  ];

  useModifiers({ modifierBtns, converterBtns, element });

  return (
    <div
      {...attributes}
      role="region"
      aria-label="In evidenza"
      className={`highlight-box highlight-box--${element.background}`}
    >
      {children}
    </div>
  );
}

export { HighlightBox };
