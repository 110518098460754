import React, { useRef } from "react";
import { useParams, useOutletContext, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import Spinner from "../components/Spinner";
import Toolbar from "../layout/Toolbar";
import Editor from "../Editor";
import {
  arraysEqual,
  buildSummary,
  createEditorContentsFromToc,
  parseTocToSave,
  stringifyNode,
} from "../Editor/utils";
import headingTypes from "../Editor/Blocks/Heading/headingTypes";
import { VisibleHeadingContextProvider } from "../Editor/Blocks/Heading/useVisibleHeadingContext";
import { useAuth, withAuthetication } from "../Auth";
import { getChapter, saveChapter } from "../Api";
import { isMobile } from "../utils/deviceInfo";
import PropTypes from "prop-types";

const MAIN_CONTENT_ID = "contenuto-principale";

function BookChapter({ resizeTopbar, setMainContentId }) {
  const {
    userInfo: { canEdit },
  } = useAuth();
  const { summary } = useOutletContext(); // remote summary sections
  const [bookSummary, setBookSummary] = React.useState(summary); // local parsed copy kept updated
  const isMobileDevice = React.useMemo(() => isMobile(), []);

  const { chapter_id: chapterId } = useParams();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(!canEdit || isMobileDevice);
  const [furthestVisibleHeading, setFurthestVisibleHeading] = React.useState({
    id: "",
    disabled: false,
  });
  const [headingsAvailable, setHeadingsAvailable] = React.useState([]);
  const [contents, setContents] = React.useState([]);
  const [bookInfo, setBookInfo] = React.useState({});
  const [currentChapterIndex, setCurrentChapterIndex] = React.useState(-1);
  const headingsFilteredRef = React.useRef(null);

  React.useEffect(() => {
    setBookSummary(summary);
    const index = summary.findIndex(
      (section) => section.id.toString() === chapterId,
    );
    setCurrentChapterIndex(index);
  }, [chapterId, summary]);

  const mounted = useRef(true);
  React.useEffect(() => {
    (async () => {
      mounted.current = true;

      const index = summary.findIndex(
        (section) => section.id.toString() === chapterId,
      );
      setCurrentChapterIndex(index);
      setLoading(true);

      try {
        const chapter = await getChapter(chapterId);
        if (!mounted.current) {
          return;
        }

        const content =
          chapter.content.length === 0
            ? createEditorContentsFromToc(chapter.toc.children)
            : chapter.content;
        setContents(content);
        setBookInfo({
          id: chapter.id,
          book: chapter.book,
          order: chapter.order,
        });
      } catch (_) {
        if (mounted.current) {
          setError(true);
        }
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    })();

    return () => {
      mounted.current = false;
    };
  }, [chapterId, summary]);

  React.useEffect(() => {
    if (loading) return;

    if (location.hash) {
      document
        .getElementById(`${decodeURIComponent(location.hash.split("#")[1])}`)
        ?.scrollIntoView();
    } else {
      window.scrollTo(0, location.state?.scrollY || 0);
    }
  }, [location.state, location.hash, loading]);

  React.useEffect(() => {
    const headingsFiltered = contents.filter((c) => c.type === headingTypes);

    if (
      headingsFilteredRef.current !== null &&
      arraysEqual(headingsFiltered, headingsFilteredRef.current)
    ) {
      // Se non sono state fatte modifiche ai titoli non aggiorna il sommario
      return;
    } else if (currentChapterIndex === -1) {
      // Se è a -1 siamo nel render iniziale con content=[], teniamo a null in quanto vogliamo rigenerare il sommario
      headingsFilteredRef.current = null;
    } else {
      headingsFilteredRef.current = headingsFiltered;
    }

    const headings = headingsFiltered.map((h, id) => ({
      ...h,
      id,
      slug: stringifyNode(h, { separator: "-" }),
    }));

    setHeadingsAvailable(headings);
    const builtSummary = buildSummary(headings);
    setBookSummary((prev) => {
      if (currentChapterIndex === -1) return prev;

      const newSummary = [...prev];
      newSummary[currentChapterIndex].children = builtSummary;
      newSummary[currentChapterIndex].flatChildrenSlugs = [
        ...new Set(
          builtSummary.map((s) => [...s.flatChildrenSlugs, s.slug]).flat(),
        ),
      ]; // remove duplicates;
      return newSummary;
    });
  }, [contents, currentChapterIndex]);

  const onCurrentChapterTitleChange = (event) => {
    setBookSummary((prev) => {
      const newSummary = [...prev];
      if (currentChapterIndex === -1) return prev;

      newSummary[currentChapterIndex].title = event.target.value;
      return newSummary;
    });
  };

  const onSave = async () => {
    const data = {
      ...bookInfo,
      title: bookSummary[currentChapterIndex].title,
      toc: parseTocToSave(bookSummary[currentChapterIndex]),
      content: contents,
    };

    try {
      await toast.promise(
        saveChapter(chapterId, data),
        {
          loading: "Salvataggio...",
          success: "Capitolo salvato",
          error: "Errore nel salvataggio",
        },
        {
          style: { minWidth: "230px" },
        },
      );
    } catch (_) {
      // Errore gestito nel toast
    }
  };

  const visibleHeadingObject = React.useMemo(
    () => ({
      furthestVisibleHeading,
      setFurthestVisibleHeading,
      headingsAvailable,
    }),
    [furthestVisibleHeading, setFurthestVisibleHeading, headingsAvailable],
  );

  React.useEffect(() => {
    setMainContentId(MAIN_CONTENT_ID);
  }, [setMainContentId]);

  return (
    <VisibleHeadingContextProvider value={visibleHeadingObject}>
      <Toolbar
        canEdit={canEdit && !isMobileDevice}
        isReadOnly={readOnly}
        setReadOnly={setReadOnly}
        onCurrentChapterTitleChange={onCurrentChapterTitleChange}
        currentChapterIndex={currentChapterIndex}
        bookSummary={bookSummary}
        onSave={onSave}
        resizeTopbar={resizeTopbar}
      />
      {loading && <Spinner />}
      {!loading && (
        <main
          aria-label="Contenuto del libro"
          className="book-container"
          id={MAIN_CONTENT_ID}
        >
          {error && <p className="text-center">Si è verificato un errore</p>}
          {!error && (
            <Editor
              readOnly={readOnly}
              setReadOnly={setReadOnly}
              contents={contents}
              setContents={setContents}
            />
          )}
        </main>
      )}
    </VisibleHeadingContextProvider>
  );
}

BookChapter.propTypes = {
  resizeTopbar: PropTypes.shape({
    setElement: PropTypes.func.isRequired,
    addEventListener: PropTypes.func.isRequired,
    removeEventListener: PropTypes.func.isRequired,
  }).isRequired,
  setMainContentId: PropTypes.func.isRequired,
};

export default withAuthetication(BookChapter);
