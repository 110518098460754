import React, { AnchorHTMLAttributes } from "react";
import { Descendant, Transforms, createEditor } from "slate";
import {
  Slate,
  Editable,
  withReact,
  useReadOnly,
  ReactEditor,
  useSlateStatic,
} from "slate-react";
import { Node as SlateNode } from "slate";
import * as Toggletip from "~/src/components/Toggletip";

import { useToolbar, DEFAULT_TOOLBAR_BUTTONS } from "../../Toolbar";

export interface Props {
  /**
   * Passati e gestiti da Slate.js
   */
  children: unknown;
  element: ElementNode;
  /**
   * Attributi generati da Slate.js da assegnare all'elemento
   */
  attributes: AnchorHTMLAttributes<HTMLAnchorElement>;
}

export type ElementNode = SlateNode & {
  /**
   * Testo contenuto nel fumetto della nota
   */
  description: Descendant[];
};

/**
 * Componente che renderizza un fumetto sopra a una o più parole per indicare una nota associata ad essi.
 * Utilizza un'istanza interna di Slate per gestire il testo della nota.
 */
function Glossario({ attributes, element, children }: Props) {
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);
  const [contents, setContents] = React.useState<Descendant[]>([
    { children: element.description },
  ]);
  const parentEditor = useSlateStatic() as ReactEditor;
  const isReadOnly = useReadOnly();

  const editor = React.useMemo(
    () => withReact(createEditor() as ReactEditor),
    [],
  );

  React.useEffect(() => {
    const path = ReactEditor.findPath(parentEditor, element);
    const nodeContent: Partial<ElementNode> = { description: [] };
    if (contents[0] !== undefined && "children" in contents[0]) {
      nodeContent.description = contents[0].children;
    }
    Transforms.setNodes(parentEditor, nodeContent, { at: path });
  }, [contents, element, parentEditor]);

  return (
    <Toggletip.Root>
      <Toggletip.Trigger className="focus-within:outline-none focus-within:bg-accent focus-within:text-accent-content">
        <span
          {...attributes}
          className="hover:cursor-pointer border-b-2 border-primary"
        >
          {children}
        </span>
      </Toggletip.Trigger>
      <Toggletip.Content className="bg-primary text-primary-content rounded p-2 z-editor-dialog max-w-[250px] sm:max-w-lg">
        <Toggletip.Arrow className="bg-transparent fill-primary" />
        <Slate editor={editor} value={contents} onChange={setContents}>
          <Editable readOnly={isReadOnly} />
        </Slate>
      </Toggletip.Content>
    </Toggletip.Root>
  );
}

export default Glossario;
