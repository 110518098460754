import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";
import { ReadonlyDeep } from "../utils/reflection";
import { useStateRef } from "../hooks/useStateRef";

export interface EditorMethods {
  readonly assignAvailableId: (id: string) => string;
  readonly preassignAvailableId: (id: string) => string;
  readonly freeId: (id: string) => void;
  readonly allocateHeading: (level: HeadingLevel) => HeadingAllocator;
  readonly allocateAutomaticHeading: () => AutomaticHeadingAllocator;
  readonly levelOf: (id: Id) => HeadingLevel | undefined;
}

const DEFAULT_METHODS: EditorMethods = {
  assignAvailableId: (id) => id,
  preassignAvailableId: (id) => id,
  freeId: (_id) => undefined,
  allocateHeading: (_level) => ({
    changeLevel: (_level: HeadingLevel) => undefined,
    remove: () => undefined,
  }),
  allocateAutomaticHeading: () => ({
    id: {
      value: 0,
      __sym: idSymbol,
    },
    remove: () => undefined,
  }),
  levelOf: () => undefined,
};

const DEFAULT_HEADING_LEVEL = 3;

const EditorMethods = createContext(DEFAULT_METHODS);

export function EditorProvider({
  children,
}: ReadonlyDeep<PropsWithChildren<NonNullable<unknown>>>): ReactElement {
  const usedIdsRef = useRef<Record<string, number[]>>({});
  const preassignedIdsRef = useRef<Record<string, number[]>>({});

  const {
    allocate: allocateHeading,
    allocateAutomatic: allocateAutomaticHeading,
    levelOf,
  } = useAllocatedHeadings();

  const assignAvailableId = useCallback(
    (id: string) =>
      assignAvailableIdImpl(id, usedIdsRef.current, preassignedIdsRef),
    [],
  );
  const preassignAvailableId = useCallback(
    (id: string) => assignAvailableIdCommon(id, preassignedIdsRef.current),
    [],
  );
  const freeId = useCallback((id: string) => {
    freeIdImpl(id, usedIdsRef.current);
  }, []);

  const methods = useMemo(
    () => ({
      assignAvailableId,
      preassignAvailableId,
      freeId,
      allocateHeading,
      allocateAutomaticHeading,
      levelOf,
    }),
    [
      allocateAutomaticHeading,
      allocateHeading,
      assignAvailableId,
      freeId,
      levelOf,
      preassignAvailableId,
    ],
  );

  return (
    <EditorMethods.Provider value={methods}>{children}</EditorMethods.Provider>
  );
}

export function useEditorContext(): EditorMethods {
  return useContext(EditorMethods);
}

export type HeadingLevel = 3 | 4 | 5 | 6;

export interface HeadingAllocator {
  readonly changeLevel: (level: HeadingLevel) => void;
  readonly remove: () => void;
}

export interface AutomaticHeadingAllocator {
  readonly id: Id;
  readonly remove: () => void;
}

const idSymbol = Symbol();

export interface Id {
  readonly value: number;
  readonly __sym: typeof idSymbol;
}

export function assignAvailableIdImpl(
  id: string,
  usedIds: Record<string, number[]>,
  preassignedIdsRef: MutableRefObject<Record<string, number[]>>,
): string {
  if (Object.keys(preassignedIdsRef.current).length !== 0) {
    preassignedIdsRef.current = {};
  }

  return assignAvailableIdCommon(id, usedIds);
}

export function assignAvailableIdCommon(
  id: string,
  usedIds: Record<string, number[]>,
): string {
  const usedIdIndices = usedIds[id];
  if (usedIdIndices === undefined) {
    usedIds[id] = [0];
    return id;
  }

  let availableIndex = usedIdIndices.findIndex(
    (value, index) => value !== index,
  );
  if (availableIndex === -1) {
    availableIndex = usedIdIndices.length;
    usedIdIndices.push(availableIndex);
  } else {
    usedIdIndices.splice(availableIndex, 0, availableIndex);
  }
  if (availableIndex === 0) {
    return id;
  } else {
    return `${id}--${availableIndex + 1}`;
  }
}

export function freeIdImpl(
  id: string,
  usedIds: Record<string, number[]>,
): void {
  let actualId: string;
  let idIndex: number;

  const doubleDashIndex = id.lastIndexOf("--");
  if (doubleDashIndex === -1) {
    actualId = id;
    idIndex = 0;
  } else {
    idIndex = Number(id.substring(doubleDashIndex + 2));
    if (isNaN(idIndex)) {
      actualId = id;
      idIndex = 0;
    } else {
      actualId = id.substring(0, doubleDashIndex);
      idIndex -= 1;
    }
  }

  const usedIdIndices = usedIds[actualId];
  if (usedIdIndices === undefined) {
    return;
  }

  const indexPosition = usedIdIndices.indexOf(idIndex);
  if (indexPosition !== -1) {
    usedIdIndices.splice(indexPosition, 1);
  }
}

interface UseAllocatedHeadings {
  allocate: (level: HeadingLevel) => HeadingAllocator;
  allocateAutomatic: () => AutomaticHeadingAllocator;
  levelOf: (id: Id) => HeadingLevel | undefined;
}

interface ExplicitAllocatedHeading {
  kind: "explicit";
  id: number;
  level: HeadingLevel;
}

interface AutomaticAllocatedHeading {
  kind: "automatic";
  id: number;
}

export type AllocatedHeading =
  | ExplicitAllocatedHeading
  | AutomaticAllocatedHeading;

function useAllocatedHeadings(): UseAllocatedHeadings {
  const [headings, setHeadings, headingsRef] = useStateRef<
    Readonly<AllocatedHeading>[]
  >([]);

  return useAllocatedHeadingsImpl(headings, setHeadings, headingsRef);
}

export function useAllocatedHeadingsImpl(
  headings: Readonly<AllocatedHeading>[],
  setHeadings: React.Dispatch<
    React.SetStateAction<Readonly<AllocatedHeading>[]>
  >,
  headingsRef: {
    readonly current: readonly ReadonlyDeep<Readonly<AllocatedHeading>>[];
  },
): UseAllocatedHeadings {
  const allocate = useCallback(
    (level: HeadingLevel): HeadingAllocator => {
      const id = Math.random();
      setHeadings(
        (headings) =>
          [
            ...headings,
            { kind: "explicit", id, level },
          ] satisfies Readonly<AllocatedHeading>[],
      );

      return {
        changeLevel: (level: HeadingLevel) => {
          const headingIndex = findAllocatedHeadingRefIndex(headingsRef, id);
          if (headingIndex !== -1) {
            setHeadings(
              (headings) =>
                [
                  ...headings.slice(0, headingIndex),
                  { kind: "explicit", id, level },
                  ...headings.slice(headingIndex + 1),
                ] satisfies Readonly<AllocatedHeading>[],
            );
          }
        },
        remove: () => {
          removeAllocatedHeading(id, headingsRef, setHeadings);
        },
      };
    },
    [headingsRef, setHeadings],
  );

  const allocateAutomatic = useCallback((): AutomaticHeadingAllocator => {
    const id = Math.random();
    setHeadings((headings) => {
      return [
        ...headings,
        { kind: "automatic", id },
      ] satisfies Readonly<AllocatedHeading>[];
    });

    return {
      id: { value: id, __sym: idSymbol },
      remove: () => {
        removeAllocatedHeading(id, headingsRef, setHeadings);
      },
    };
  }, [headingsRef, setHeadings]);

  const levelOf = useCallback(
    (id: Id): HeadingLevel | undefined => {
      const heading = findAllocatedHeading(headings, id.value);
      if (heading === undefined || heading.kind !== "automatic") {
        return undefined;
      }

      return findAutomaticAllocationLevel(heading, headings);
    },
    [headings],
  );

  return {
    allocate,
    allocateAutomatic,
    levelOf,
  };
}

function findAllocatedHeadingRefIndex(
  allocatedHeadings: MutableRefObject<readonly Readonly<AllocatedHeading>[]>,
  id: number,
): number {
  return allocatedHeadings.current.findIndex((heading) => heading.id === id);
}

function findAllocatedHeading<T extends Readonly<AllocatedHeading>>(
  allocatedHeadings: readonly T[],
  id: number,
): T | undefined {
  return allocatedHeadings.find((heading) => heading.id === id);
}

function findAutomaticAllocationLevel(
  heading: Readonly<AutomaticAllocatedHeading>,
  headings: readonly Readonly<AllocatedHeading>[],
): HeadingLevel {
  let parentIndex = headings.findIndex((h) => h.id === heading.id) - 1;
  for (;;) {
    const parentHeading = headings[parentIndex];
    if (parentHeading === undefined) {
      return DEFAULT_HEADING_LEVEL;
    }

    switch (parentHeading.kind) {
      case "explicit":
        // SAFETY: 3|4|5|6 + 1 === 4|5|6|7; min(4|5|6|7, 6) === 4|5|6
        return Math.min(parentHeading.level + 1, 6) as HeadingLevel;
      case "automatic": {
        parentIndex -= 1;
      }
    }
  }
}

function removeAllocatedHeading(
  id: number,
  headingsRef: MutableRefObject<readonly Readonly<AllocatedHeading>[]>,
  setHeadings: Dispatch<SetStateAction<AllocatedHeading[]>>,
): void {
  const headingIndex = findAllocatedHeadingRefIndex(headingsRef, id);
  const heading = headingsRef.current[headingIndex];
  if (heading === undefined) {
    return;
  }

  setHeadings((headings) => {
    const newHeadings = [
      ...headings.slice(0, headingIndex),
      ...headings.slice(headingIndex + 1),
    ];
    headingsRef.current = newHeadings;
    return newHeadings;
  });
}
