import React, { ReactElement, useRef } from "react";
import * as Slate from "slate";

export interface ModifiersContext {
  modifierBtns: Slate.Element[];
  converterBtns: ReactElement[];
  setButtons: (buttons: Omit<Modifiers, "element">) => void;
}

/**
 * Il contesto React a cui i blocchi accederanno tramite l'hook `useModifiers`.
 */
export const ModifiersContext = React.createContext<ModifiersContext>({
  modifierBtns: [],
  converterBtns: [],
  setButtons: () => undefined,
});

const EMPTY_ARRAY: unknown[] = [];

export interface Modifiers {
  /** array con i bottoni da visualizzare */
  modifierBtns?: Slate.Element[];
  converterBtns?: ReactElement[];
  /** element di Slate.js */
  element?: undefined | null | Slate.Element;
}

/**
 * Hook per gestire i modifiers di un blocco.
 * L'hook viene chiamato all'interno dei blocchi per definire se e quali bottoni
 * saranno disponibili per la modifica del blocco stesso.
 *
 * Esempio:
 * ```
 *   useModifiers([<ModifierBtn props={...} />, <ModifierBtn props={...} />], elementSlate)
 * ```
 * oppure ignorando il parametro per non visualizzare i modifier
 * ```
 *   useModifiers()
 * ```
 * oppure non scrivendo `useModifiers`
 */
function useModifiers({
  modifierBtns = EMPTY_ARRAY as Slate.Element[],
  converterBtns = EMPTY_ARRAY as ReactElement[],
  element = null,
}: Modifiers = {}) {
  const { setButtons } = React.useContext(ModifiersContext);
  const setButtonsRef = useRef(setButtons);
  const modifierBtnsRef = useRef(modifierBtns);
  const converterBtnsRef = useRef(converterBtns);

  React.useEffect(() => {
    setButtonsRef.current = setButtons;
  }, [setButtons]);

  React.useEffect(() => {
    modifierBtnsRef.current = modifierBtns;
  }, [modifierBtns]);

  React.useEffect(() => {
    converterBtnsRef.current = converterBtns;
  }, [converterBtns]);

  React.useEffect(() => {
    setButtonsRef.current({
      modifierBtns: modifierBtnsRef.current,
      converterBtns: converterBtnsRef.current,
    });
  }, [element]);
}

export default useModifiers;
