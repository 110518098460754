import { jsx } from "slate-hyperscript";
import { ELEMENT_TYPES } from "../constants";

import { ELEMENT_TAGS, TagAttributes, TEXT_TAGS } from "./nativeTagsMaps";
import { BaseElement, BaseText, Descendant } from "slate";

export function makeDeserializer() {
  function deserialize(el: Element | ChildNode) {
    if (
      "attributes" in el &&
      el.attributes.getNamedItem("class")?.value.match(/done/g)
    ) {
      return null;
    }

    if (isList(el)) {
      return deserializeList(el);
    }
    return deserializeElement(el);
  }

  function deserializeList(el: Element): BaseElement {
    const siblings = getSiblings(el);
    const type = "UL";
    const listWrapper = document.createElement(type);
    for (const sibling of siblings) {
      listWrapper.appendChild(sibling);
    }

    const attrs = ELEMENT_TAGS[type](listWrapper);
    const children = Array.from(listWrapper.childNodes)
      .map(deserializeListItem)
      .flat();

    return jsx("element", attrs, children);
  }

  function deserializeElement(
    el: Element | ChildNode,
  ): (string | Descendant)[] | BaseElement | string | null | BaseText[] {
    if (el.nodeType === Node.TEXT_NODE) {
      if (el.parentNode?.nodeName === "O:P") {
        return "";
      }

      // Apple converte gli spazi tra testo in span che hanno questa classe e non vanno rimossi
      if (
        el.parentNode instanceof Element &&
        el.parentNode.className.includes("Apple-converted-space")
      ) {
        return " ";
      }

      const textContent = el.textContent?.replace(/\n/g, " ");

      if (textContent === undefined || textContent.match(/^[\s]*$/g)) {
        // Word incolla numerosi blocchi vuoti che rimuoviamo ritornando null
        return null;
      } else {
        return textContent;
      }
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
      return null;
    } else if (el.nodeName === "BR") {
      return " ";
    }

    const { nodeName } = el;

    const parent = el;

    let children = Array.from(parent.childNodes).map(deserialize).flat();

    if (children.length === 0) {
      children = [{ text: "" }];
    }

    if (el.nodeName === "BODY") {
      return jsx("fragment", {}, children);
    }

    if (nodeName in ELEMENT_TAGS) {
      const attrs = ELEMENT_TAGS[nodeName as keyof typeof ELEMENT_TAGS](
        el as Element,
      );
      return jsx("element", attrs, children);
    }

    if (nodeName in TEXT_TAGS) {
      const attrs = TEXT_TAGS[nodeName as keyof typeof TEXT_TAGS]();
      return children.map((child) => jsx("text", attrs, child));
    }

    return children.filter((element) => element !== null);
  }

  function deserializeListItem(el: Node): BaseElement {
    const content = getTextfromList(el)
      .map((c) => deserializeElement(c))
      .flat();

    return jsx("element", { type: ELEMENT_TYPES.listItem }, content);
  }

  return deserialize;
}

// Prende tutti i sibling della lista
function getSiblings(el: Element): Element[] {
  const siblings: Element[] = [];
  let element: Element | null = el;

  while (
    element?.attributes.getNamedItem("class")?.value.match(/MsoListParagraph/g)
  ) {
    element.setAttribute("class", "done"); // we set this attribute to avoid getting stuck in an infinite loop
    siblings.push(element);
    element = element.nextElementSibling;
  }

  return siblings;
}

// Docx lists begin with "MsoListParagraph".
function isList(el: Element | ChildNode): el is Element {
  return !!(
    "attributes" in el &&
    el.attributes.getNamedItem("class")?.value.match(/MsoListParagraph/g)
  );
}

// Riceve un list-item e prende il testo dentro. Il testo potrebbe essere dentro a uno span o a un #text
function getTextfromList(el: Node) {
  const children = Array.from(el.childNodes);
  const result: (Element | Text)[] = [];

  children.forEach((child) => {
    if (
      (TEXT_TAGS as Record<string, () => TagAttributes>)[child.nodeName] ||
      child.nodeName === "#text"
    ) {
      result.push(child as Element | Text);
    } else if (child.nodeName === "SPAN") {
      if (child.textContent !== null) {
        child.textContent = child.textContent.replace(
          /(^(\W)(?=\s)*)|(o\s)(?!\w)/gm,
          "",
        );
      }

      result.push(child as Element);
    }
  });

  return result;
}
