import React from "react";

import Button from "./ButtonBase";
import IconCmpt from "../Icon";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../Tooltip";
import { IconType } from "react-icons/lib";

export interface Props {
  readonly onClick?: (() => void) | undefined;
  readonly Icon: IconType;
  readonly ariaLabel?: string | undefined;
}

/**
 * Componente per i pulsanti di azione (es: sposta, elimina, preview blocco per aggiunta/trasformazione)
 */
function ButtonAction({ onClick, Icon, ariaLabel }: Props) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            square
            outline
            type="primary"
            onClick={onClick}
            ariaLabel={ariaLabel}
          >
            <IconCmpt label={ariaLabel} icon={Icon} size="30px" />
          </Button>
        </TooltipTrigger>
        <TooltipContent sideOffset={2} side="top">
          {ariaLabel}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default React.memo(ButtonAction);
