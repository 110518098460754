import React, { ButtonHTMLAttributes } from "react";

export const BACKGROUND_COLORS = {
  primary: "primary",
  secondary: "secondary",
  accent: "accent",
} as const;

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: keyof typeof BACKGROUND_COLORS;
}

function ModifierButton({ color, ...props }: Props) {
  return (
    <button
      {...props}
      className={`highlight-box-modifier highlight-box-modifier--${color}`}
    />
  );
}

export default ModifierButton;
