export function updateNoStickyAttribute(headerHeight: number): void {
  if (headerHeight < window.innerHeight * 0.4) {
    document.documentElement.removeAttribute("data-no-sticky");
  } else {
    document.documentElement.setAttribute("data-no-sticky", "");
  }
}

export function updateNoStickyAttributeFromBarHeights(): void {
  window.addEventListener("resize", () => {
    const topbar = document.getElementsByClassName("topbar")[0];
    if (topbar === undefined) {
      return;
    }

    let headerHeight = topbar.clientHeight;

    const toolbar = document.getElementsByClassName("toolbar")[0];
    if (toolbar !== undefined) {
      headerHeight += toolbar.clientHeight;
    }

    updateNoStickyAttribute(headerHeight);
  });
}
