import React, { Dispatch, SetStateAction, useCallback } from "react";
import {
  useParams,
  Outlet,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import toast from "react-hot-toast";

import Spinner from "../components/Spinner";
import Header from "../layout/Header";
import {
  apiDeleteChapter,
  BookSummary,
  BookSummaryBook,
  ChapterTOC,
  getBookSummary,
  getChapter,
  postChapter,
} from "../Api";
import {
  Bookmark,
  getAutomaticBookmarkName,
  loadBookmark,
  useAutomaticBookmark,
  useManualBookmark,
} from "../utils/bookmark";
import { withAuthetication } from "../Auth";
import { ReadonlyDeep } from "../utils/reflection";

export interface Props {
  setTopbar: (element: HTMLElement) => void;
  setMainContentId: Dispatch<SetStateAction<undefined | string>>;
}

const MAIN_CONTENT_ERROR_ID = "contenuto-principale";

function Book({ setTopbar, setMainContentId }: ReadonlyDeep<Props>) {
  const { isbn = "", chapter_id: chapterId = "" } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [automaticBookmark, setAutomaticBookmark] =
    React.useState<Bookmark | null>(null);
  const { manualBookmark, updateManualBookmark, navigateToManualBookmark } =
    useManualBookmark(isbn, chapterId);
  const [bookSummary, setBookSummary] = React.useState<
    { book: Partial<BookSummaryBook> } & Omit<BookSummary, "book">
  >({
    book: {},
    chapters: [],
  });
  useAutomaticBookmark(isbn, chapterId);

  const redirectDataRef = React.useRef({
    chapterId,
    locationHash: location.hash,
    navigate,
  });

  React.useEffect(() => {
    redirectDataRef.current = {
      chapterId,
      locationHash: location.hash,
      navigate,
    };
  }, [chapterId, location.hash, navigate]);

  const redirectToChapterIfNecessary = useCallback(
    (sections: ChapterTOC[] = []) => {
      const bookmark = loadBookmark(getAutomaticBookmarkName(isbn));
      setAutomaticBookmark(bookmark);

      const { chapterId, locationHash, navigate } = redirectDataRef.current;

      // Se presente hash in url non aggiorna il location.state
      if (locationHash) return;

      let chapterToRedirect = chapterId;
      let scrollY = 0;

      if (!chapterId && bookmark) {
        chapterToRedirect = bookmark.chapterId;
        scrollY = bookmark.scrollY;
      } else if (!chapterId && sections[0] !== undefined) {
        chapterToRedirect = sections[0].id.toString();
      } else if (chapterId && sections[0] !== undefined) {
        const chapterExists = sections.find(
          (s) => s.id.toString() === chapterId,
        );
        if (!chapterExists) chapterToRedirect = sections[0]?.id.toString();
        if (chapterExists && chapterId === bookmark?.chapterId)
          scrollY = bookmark.scrollY;
      }

      navigate(chapterToRedirect, { replace: true, state: { scrollY } });
    },
    [isbn],
  );

  React.useEffect(() => {
    void (async () => {
      try {
        setLoading(true);
        const summaryData = await getBookSummary(isbn);
        setBookSummary(summaryData);
        redirectToChapterIfNecessary(summaryData.chapters);
      } catch {
        setMainContentId(MAIN_CONTENT_ERROR_ID);
        setError(true);
      } finally {
        setLoading(false);
      }
    })().catch();
  }, [isbn, redirectToChapterIfNecessary, setMainContentId]);

  const createChapter = async (node: { id: number }) => {
    setLoading(true);
    const chapterIndex = bookSummary.chapters.findIndex(
      (c) => c.id === node.id,
    );
    const nextChapterIndex =
      bookSummary.chapters.length - 1 >= chapterIndex + 1
        ? chapterIndex + 1
        : null;

    let chapter = null;
    let nextChapter = null;

    try {
      // Recupera le informazione dell'ordine per il capitolo attuale e successivo
      chapter = await getChapter(node.id);
      if (
        nextChapterIndex &&
        bookSummary.chapters[nextChapterIndex] !== undefined
      ) {
        nextChapter = await getChapter(
          bookSummary.chapters[nextChapterIndex].id,
        );
      }

      let order = null;
      if (nextChapter) {
        order = chapter.order + (nextChapter.order - chapter.order) / 2;
      } else {
        order = chapter.order + 1;
      }
      const bookId = bookSummary.book.id;
      if (bookId === undefined) {
        throw new Error("Book is should be set");
      }
      const result = await postChapter(bookId, order);

      setBookSummary(({ book, chapters }) => {
        const newChapters = [
          ...chapters.slice(0, chapterIndex + 1),
          result.toc,
          ...chapters.slice(chapterIndex + 1, chapters.length),
        ];
        return {
          book,
          chapters: newChapters,
        };
      });

      navigate(result.id.toString());
    } catch {
      toast.error("Errore nella creazione");
    } finally {
      setLoading(false);
    }
  };

  const deleteChapter = async (node: { id: number }) => {
    const chapterIndex = bookSummary.chapters.findIndex(
      (c) => c.id === node.id,
    );

    try {
      await toast.promise(
        apiDeleteChapter(node.id),
        {
          loading: "Cancellazione...",
          success: "Capitolo cancellato",
          error: "Capitolo non cancellato",
        },
        {
          style: { minWidth: "230px" },
        },
      );

      const newChapters = [
        ...bookSummary.chapters.slice(0, chapterIndex),
        ...bookSummary.chapters.slice(
          chapterIndex + 1,
          bookSummary.chapters.length + 1,
        ),
      ];

      setBookSummary(({ book }) => ({
        book,
        chapters: newChapters,
      }));

      if (chapterId === node.id.toString()) {
        if (newChapters[chapterIndex]) {
          navigate(newChapters[chapterIndex].id.toString());
        } else {
          const lastChapter = newChapters[newChapters.length - 1];
          if (lastChapter) {
            navigate(lastChapter.id.toString());
          }
        }
      }
    } catch {
      // gestito nel toast
    }
  };

  return (
    <>
      <Header bookTitle={bookSummary.book.title} setTopbar={setTopbar} />
      {loading && <Spinner />}
      {!loading && error && (
        <main
          aria-label="Contenuto del libro"
          className="book-container"
          id={MAIN_CONTENT_ERROR_ID}
        >
          <p className="text-center">Si è verificato un errore</p>
        </main>
      )}
      {!loading && !error && (
        <Outlet
          context={
            {
              summary: bookSummary.chapters,
              automaticBookmark,
              manualBookmark,
              createChapter,
              deleteChapter,
              updateManualBookmark,
              navigateToManualBookmark,
            } satisfies Context
          }
        />
      )}
    </>
  );
}

export interface Context {
  summary: ChapterTOC[];
  automaticBookmark: Bookmark | null;
  manualBookmark: Bookmark | null | undefined;
  createChapter: (node: { id: number }) => Promise<void>;
  deleteChapter: (node: { id: number }) => Promise<void>;
  updateManualBookmark: (chapterTitle?: string) => void;
  navigateToManualBookmark: () => void;
}

export function useBookContext(): Context {
  return useOutletContext<Context>();
}

export default withAuthetication(Book);
