import React, { useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import PropTypes from "prop-types";

import UICustomizer from "./UICustomizer";
import SideBar from "./SideBar";
import ScrollProgress from "./ScrollProgress";
import Button from "~/src/components/Button";
import Icon from "~/src/components/Icon";
import Switch from "~/src/components/Switch";
import { updateNoStickyAttribute } from "~/src/utils/resize";

function Toolbar({
  canEdit,
  isReadOnly,
  setReadOnly,
  currentChapterIndex,
  onCurrentChapterTitleChange,
  bookSummary,
  onSave,
  resizeTopbar,
}) {
  const chapterTitle = bookSummary[currentChapterIndex]?.title || "";
  const chapterProgress = `${currentChapterIndex + 1} di ${bookSummary.length}`;
  const prevChapterId =
    currentChapterIndex > 0 ? bookSummary[currentChapterIndex - 1].id : -1;
  const nextChapterId =
    currentChapterIndex < bookSummary.length - 1
      ? bookSummary[currentChapterIndex + 1].id
      : -1;

  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = document.addEventListener("keyup", (evt) => {
      if (evt.key === "ArrowLeft" && evt.shiftKey && evt.altKey) {
        if (prevChapterId > 0) {
          navigate(`../${prevChapterId}`);
        }
      } else if (evt.key === "ArrowRight" && evt.shiftKey && evt.altKey) {
        if (nextChapterId > 0) {
          navigate(`../${nextChapterId}`);
        }
      }
    });
    return () => document.removeEventListener("keyup", unsubscribe);
  }, [
    currentChapterIndex,
    bookSummary,
    prevChapterId,
    navigate,
    nextChapterId,
  ]);

  const toolbarRef = useRef(null);
  const {
    addEventListener: resizeTopbarAddEventListener,
    removeEventListener: resizeTopbarRemoveEventListener,
    element: topbar,
  } = resizeTopbar;

  const updateToolbarTop = useCallback(
    (topbar) => updateToolbarTopImpl(toolbarRef, topbar),
    [],
  );
  useEffect(() => updateToolbarTopImpl(toolbarRef, topbar), [topbar]);
  useEffect(() => {
    resizeTopbarAddEventListener(updateToolbarTop);
    return () => resizeTopbarRemoveEventListener(updateToolbarTop);
  }, [
    resizeTopbarAddEventListener,
    resizeTopbarRemoveEventListener,
    updateToolbarTop,
  ]);

  return (
    <div className="toolbar" ref={toolbarRef}>
      <div className="toolbar__left order-2 sm:order-1">
        <SideBar
          bookSummary={bookSummary}
          chapterTitle={chapterTitle}
          isReadOnly={isReadOnly}
        />
      </div>
      <div className="toolbar__center flex order-1 sm:order-2">
        <nav className="w-full flex items-center">
          {prevChapterId !== -1 && (
            <Link
              className="btn btn-square btn-ghost"
              aria-keyshortcuts="Alt+Shift+ArrowLeft"
              to={`../${prevChapterId}`}
            >
              <Icon
                icon={MdNavigateBefore}
                label="Vai al capitolo precedente"
                className="w-full h-full p-2"
              />
            </Link>
          )}
          <div className="w-full px-2 text-center flex flex-col">
            {isReadOnly && (
              <h2 className="w-full text-lg text-center mt-4">
                {chapterTitle}
              </h2>
            )}
            {!isReadOnly && (
              <input
                className="input input-primary w-full text-lg text-center mt-4 bg-base-100"
                type="text"
                value={chapterTitle}
                onChange={onCurrentChapterTitleChange}
              />
            )}
            <span className="inline-block my-1">{chapterProgress}</span>
            <ScrollProgress />
          </div>
          {nextChapterId !== -1 && (
            <Link
              className="btn btn-square btn-ghost"
              aria-keyshortcuts="Alt+Shift+ArrowRight"
              to={`../${nextChapterId}`}
            >
              <Icon
                icon={MdNavigateNext}
                label="Vai al capitolo successivo"
                className="w-full h-full p-2"
              />
            </Link>
          )}
        </nav>
      </div>
      <div className="toolbar__right flex justify-end order-2 sm:order-3">
        <UICustomizer />
      </div>

      {canEdit && (
        <div className="editing-toolbar">
          <Switch
            type="primary"
            size="sm"
            checked={!isReadOnly}
            onCheckedChange={() => setReadOnly((prev) => !prev)}
          >
            <span className="sm:max-w-min text-center leading-none">
              Modifica libro
            </span>
          </Switch>
          {!isReadOnly && (
            <Button
              size="sm"
              className="sm:max-w-min"
              onClick={onSave}
              invertedColors
            >
              Salva modifiche
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

Toolbar.propTypes = {
  canEdit: PropTypes.bool,
  isReadOnly: PropTypes.bool.isRequired,
  setReadOnly: PropTypes.func.isRequired,
  currentChapterIndex: PropTypes.number.isRequired,
  onCurrentChapterTitleChange: PropTypes.func.isRequired,
  bookSummary: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  resizeTopbar: PropTypes.shape({
    setElement: PropTypes.func.isRequired,
    addEventListener: PropTypes.func.isRequired,
    removeEventListener: PropTypes.func.isRequired,
    element: PropTypes.instanceOf(HTMLElement),
  }).isRequired,
};

/**
 * @param toolbarRef {React.MutableRefObject<HTMLElement|null>}
 * @param topbar {HTMLElement|null}
 * @return {void}
 */
function updateToolbarTopImpl(toolbarRef, topbar) {
  if (topbar === null) {
    return;
  }

  const toolbar = toolbarRef.current;
  toolbar.style.setProperty("--top", `${topbar.clientHeight}px`);
  const headerHeight = topbar.clientHeight + toolbar.clientHeight;
  document.documentElement.style.setProperty(
    "--total-header",
    `${headerHeight}px`,
  );

  updateNoStickyAttribute(headerHeight);
}

export default Toolbar;
