import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
} from "react";
import classNames from "classnames";

// Mappiamo la prop type in uno stile bottone di DaisyUI
const BUTTON_TYPE_MAP = {
  primary: "btn-primary",
  secondary: "btn-secondary",
  accent: "btn-accent",
  info: "btn-info",
  success: "btn-success",
  warning: "btn-warning",
  error: "btn-error",
  ghost: "btn-ghost",
} as const;

const BUTTON_SIZE_MAP = {
  lg: "btn-lg",
  md: "btn-md",
  sm: "btn-sm",
  xs: "btn-xs",
};

export interface Props
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    "type" | "aria-label"
  > {
  readonly text?: string | undefined;
  readonly classes?: string | undefined;
  readonly type?: keyof typeof BUTTON_TYPE_MAP | undefined | null;
  readonly square?: boolean | undefined;
  readonly outline?: boolean | undefined;
  readonly size?: keyof typeof BUTTON_SIZE_MAP | undefined;
  readonly children?: ReactNode | undefined;
  readonly ariaLabel?: string | undefined;
  readonly invertedColors?: boolean | undefined;
}

/**
 * Componente ButtonBase stilato con DaisyUI
 * vedi https://daisyui.com/components/button
 */
const ButtonBase = forwardRef(function ButtonBase(
  {
    text,
    children,
    classes = "",
    type = null,
    square = false,
    size = "md",
    outline = false,
    ariaLabel = "",
    invertedColors = false,
    className,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
): ReactElement {
  const classesObj = {
    [BUTTON_SIZE_MAP[size]]: !!size,
    "btn-square": square,
    "btn-outline": outline,
    "btn--inverted": invertedColors,
  };
  if (type) {
    classesObj[BUTTON_TYPE_MAP[type]] = !!type;
  }
  const btnCls = classNames(className, `btn ${classes}`, classesObj);

  return (
    <button className={btnCls} ref={ref} aria-label={ariaLabel} {...props}>
      {text ?? children}
    </button>
  );
});

export default ButtonBase;
