import {
  Element as SlateElement,
  Node as SlateNode,
  Path,
  Transforms,
} from "slate";
import { ReactEditor } from "slate-react";

import { BACKGROUND_COLORS } from "../Blocks/HighlightBox/HighlightBoxModifiers";
import { ELEMENT_TYPES, ElementTypesValues, LIST_BLOCKS } from "../constants";
import { removeMarks, selectBlock, selectEndPath, unwrapNodes } from "./utils";

export interface BlockElement extends SlateElement {
  type: ElementTypesValues;
}

type Node = SlateNode & {
  type: ElementTypesValues;
};

/**
 * Cambia il blocco in un titolo
 * @param editor editor di Slate.js
 * @param element element di Slate.js
 */
export function changeToTitle(editor: ReactEditor, element: BlockElement) {
  const path = ReactEditor.findPath(editor, element);

  selectBlock(editor, path);
  unwrapNodes(editor, ELEMENT_TYPES.glossario);
  unwrapNodes(editor, ELEMENT_TYPES.link);
  removeMarks(editor);

  unwrapNodes(editor, ELEMENT_TYPES.orderedList);
  unwrapNodes(editor, ELEMENT_TYPES.unorderedList);
  unwrapNodes(editor, ELEMENT_TYPES.alphabeticList);
  unwrapNodes(editor, ELEMENT_TYPES.example);

  Transforms.setNodes<Node & { weight: number }>(editor, {
    type: ELEMENT_TYPES.heading,
    weight: 1,
  });
  selectEndPath(editor, path);
}

/**
 * Cambia il blocco in un paragrafo
 * @param editor editor di Slate.js
 * @param element element di Slate.js
 */
export function changeToParagraph(editor: ReactEditor, element: BlockElement) {
  const path = ReactEditor.findPath(editor, element);

  selectBlock(editor, path);

  unwrapNodes(editor, ELEMENT_TYPES.orderedList);
  unwrapNodes(editor, ELEMENT_TYPES.unorderedList);
  unwrapNodes(editor, ELEMENT_TYPES.alphabeticList);
  unwrapNodes(editor, ELEMENT_TYPES.example);

  if (element.type === ELEMENT_TYPES.quote) {
    Transforms.removeNodes(editor, {
      at: path.concat(element.children.length - 1),
    });
    unwrapNodes(editor, ELEMENT_TYPES.quote);
    unwrapNodes(editor, ELEMENT_TYPES.quoteText);
  }

  Transforms.setNodes<Node>(editor, { type: ELEMENT_TYPES.paragraph });
  selectEndPath(editor, path);
}

/**
 * Rimuove il highlight box e lascia i componenti dentro
 * @param editor editor di Slate.js
 * @param element element di Slate.js
 */
export function removeHighlightBox(editor: ReactEditor, element: BlockElement) {
  const path = ReactEditor.findPath(editor, element);

  unwrapNodes(editor, ELEMENT_TYPES.highlightBox, path);
}

/**
 * Cambia il blocco in una lista non ordinata
 * @param editor editor di Slate.js
 * @param element element di Slate.js
 */
export function changeToBulletedList(
  editor: ReactEditor,
  element: BlockElement,
) {
  const path = ReactEditor.findPath(editor, element);
  selectBlock(editor, path);

  if ((LIST_BLOCKS as readonly ElementTypesValues[]).includes(element.type)) {
    unwrapNodes(editor, element.type);
  } else {
    if (element.type === ELEMENT_TYPES.example) {
      unwrapNodes(editor, element.type);
    }

    Transforms.setNodes<Node>(editor, { type: ELEMENT_TYPES.listItem });
  }

  Transforms.wrapNodes(editor, {
    type: ELEMENT_TYPES.unorderedList,
    children: [],
  } as SlateElement);
  selectEndPath(editor, path);
}

/**
 * Cambia il blocco in una lista ordinata
 * @param editor editor di Slate.js
 * @param element element di Slate.js
 */
export function changeToOrderedList(
  editor: ReactEditor,
  element: BlockElement,
) {
  const path = ReactEditor.findPath(editor, element);
  selectBlock(editor, path);

  if ((LIST_BLOCKS as readonly ElementTypesValues[]).includes(element.type)) {
    unwrapNodes(editor, element.type);
  } else {
    if (element.type === ELEMENT_TYPES.example) {
      unwrapNodes(editor, element.type);
    }

    Transforms.setNodes<Node>(editor, { type: ELEMENT_TYPES.listItem });
  }

  Transforms.wrapNodes(editor, {
    type: ELEMENT_TYPES.orderedList,
    children: [],
  } as SlateElement);
  selectEndPath(editor, path);
}

/**
 * Cambia il blocco in una lista alfabetica ordinata
 * @param editor editor di Slate.js
 * @param element element di Slate.js
 */
export function changeToAlphabeticList(
  editor: ReactEditor,
  element: BlockElement,
) {
  const path = ReactEditor.findPath(editor, element);
  selectBlock(editor, path);

  if ((LIST_BLOCKS as readonly ElementTypesValues[]).includes(element.type)) {
    unwrapNodes(editor, element.type);
  } else {
    if (element.type === ELEMENT_TYPES.example) {
      unwrapNodes(editor, element.type);
    }

    Transforms.setNodes<Node>(editor, { type: ELEMENT_TYPES.listItem });
  }

  Transforms.wrapNodes(editor, {
    type: ELEMENT_TYPES.alphabeticList,
    children: [],
  } as SlateElement);
  selectEndPath(editor, path);
}

/**
 * Wrappa il blocco in un highlight box
 * @param editor editor di Slate.js
 * @param path path di Slate.js
 */
export function wrapHighlightBox(editor: ReactEditor, path: Path) {
  Transforms.wrapNodes(
    editor,
    {
      type: ELEMENT_TYPES.highlightBox,
      background: BACKGROUND_COLORS.primary,
      children: [],
    } as SlateElement,
    { at: path },
  );
}

/**
 * Transforma in quote
 * @param editor editor di Slate.js
 * @param element element di Slate.js
 */
export function changeToQuote(editor: ReactEditor, element: BlockElement) {
  const path = ReactEditor.findPath(editor, element);

  selectBlock(editor, path);

  Transforms.setNodes<Node>(editor, { type: ELEMENT_TYPES.quoteParagraph });
  Transforms.wrapNodes(editor, {
    type: ELEMENT_TYPES.quoteText,
    children: [],
  } as SlateElement);
  Transforms.wrapNodes(
    editor,
    { type: ELEMENT_TYPES.quote, children: [] } as SlateElement,
    {
      at: path,
    },
  );
  Transforms.insertNodes(
    editor,
    {
      type: ELEMENT_TYPES.quoteSource,
      children: [{ text: "(fonte)" }],
    } as SlateElement,
    { at: path.concat(1) },
  );

  selectEndPath(editor, path);
}

/**
 * Transforma in Esempio
 * @param editor editor di Slate.js
 * @param element element di Slate.js
 */
export function changeToExample(editor: ReactEditor, element: BlockElement) {
  const path = ReactEditor.findPath(editor, element);

  selectBlock(editor, path);

  if ((LIST_BLOCKS as readonly ElementTypesValues[]).includes(element.type)) {
    unwrapNodes(editor, element.type);
  }

  Transforms.setNodes<Node>(editor, { type: ELEMENT_TYPES.exampleParagraph });
  Transforms.wrapNodes(editor, {
    type: ELEMENT_TYPES.example,
    children: [],
  } as SlateElement);

  selectEndPath(editor, path);
}
