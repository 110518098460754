import React, { ComponentProps, ReactElement, ReactNode } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

export interface TooltipProps
  extends ComponentProps<typeof TooltipPrimitive.Root> {
  readonly children: ReactNode;
}

function Root({ children, ...props }: TooltipProps): ReactElement {
  return <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>;
}

export interface ContentProps
  extends ComponentProps<typeof TooltipPrimitive.Content> {
  readonly children: ReactNode;
}

function Content({ children, ...props }: ContentProps): ReactElement {
  return (
    <TooltipPrimitive.Content className="tooltip__content" {...props}>
      {children}
    </TooltipPrimitive.Content>
  );
}

export type ArrowProps = ComponentProps<typeof TooltipPrimitive.Arrow>;

function Arrow(props: ArrowProps): ReactElement {
  return <TooltipPrimitive.Arrow className="tooltip__arrow" {...props} />;
}

export const TooltipProvider = TooltipPrimitive.Provider;
export const Tooltip = Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = Content;
export const TooltipArrow = Arrow;
